.form-group {
    width: 80%;

    & label {
        display: block;
        font-size: 1.9rem;
        font-weight: 500;
        color: $color-grey-dark;
        margin-bottom: .5rem;
    }

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }

    &:last-of-type {
        margin-bottom: 4rem;
    }
}