.table-input {
    width: 7.5rem;
    padding: 1rem;

    font-family: $font-primary;
    font-size: 1.5rem;
    font-weight: 500;

    border-radius: 0;
    border: none;
    border-bottom: 2px solid $color-primary;
    outline: none;
    background-color: $color-grey-light-1;
}
