.total {
    font-size: 1.8rem;
    font-weight: 400;
    font-family: $font-primary;

    border: 2px solid $color-primary;
    border-radius: 5px;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);

    display: grid;
    grid-template-columns: repeat(2, min-content);

    &__label, &__value {
        padding: 1rem 2rem;
    }
    
    &__value {
        background-color: $color-primary;
        text-align: right;
    }
}