.product-sidebar {
    overflow-y: scroll;
    max-height: 100%;

    &__figure {
        width: 12rem;
        height: 12rem;
        padding: .5rem;

        margin-right: 4rem;

        border-radius: 5px;
        background-color: $color-grey-light-1;

        display: flex;
        justify-content: center;

        & img {
            height: 100%;
            width: auto;
        }
    }

    &__group {
        & h5 {
            font-size: 2.3rem;
            font-weight: 500;
            border-bottom: 1px solid $color-grey-light-2;
            padding-bottom: 3px;

            margin-bottom: 2rem;
            color: $color-grey-dark;
        }

        &:not(:last-child) {
            margin-bottom: 4rem;
        }
    }

    &__form {
        & > *:nth-child(4), & > *:nth-child(5) {
            width: 40%;
        }
    }
}