.blocked-days {
    &__picker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 2rem;
        margin: 0 auto;
    }
    &__block-button {
        width: 100%;
        max-width: 160px;
        z-index: 1;
    }
    &__unblock-button {
        padding: 0.7rem 1.4rem;
        font-size: 1.2rem;
    }

    &__error-wrapper {
        margin-top: 2rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid $color-grey-light-2;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        background-color: $color-grey-light-3;
        border-radius: 4px;

        span {
            font-size: 1.6rem;
            color: $color-black;
            font-weight: 500;
        }
    }
}
