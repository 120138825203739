.dropdown {
    width: 30rem;
    margin-right: -3.25rem;
    padding: 1.5rem 1.5rem;
    border-radius: 3px;

    font-family: inherit;
    font-size: inherit;

    color: inherit;
    background-color: $color-white;
    border: 2px solid $color-primary;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);

    &::placeholder {
        font-size: 1.5rem;
    }

    &:checked {
        border: 5px solid $color-primary;
    }
}