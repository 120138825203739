.input {
    width: 100%;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $color-primary;
    padding: 1.2rem 1.3rem;
    font-family: $font-primary;
    font-size: 1.8rem;
    font-weight: 500;
    outline: none;
    background-color: $color-grey-light-1;

    &:disabled {
        background-color: #dedede;
    }

    &[type="checkbox"] {
        width: 2.3rem;
        height: 2.3rem;
    }
}