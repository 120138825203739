.barber-card {
    display: grid;
    grid-template-rows: 1fr min-content minmax(min-content, 3.5rem);
    justify-items: center;
    
    width: 29rem;
    
    background-color: $color-grey-light-1;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: -.9rem 1.6rem 3.1rem rgba(#000, .16);

    &__figure {
        height: 29.5rem;
        padding: 1rem;
        margin-bottom: 1.5rem;

        border-radius: 5px;
        background-color: #fff;

        overflow: hidden;

        & img {
            width: 100%;
            filter:  drop-shadow(0 .5rem 1rem rgba(#000, .25));
        }
    }

    &__name {
        font-size: 2.3rem;
    }
    
    &__rank {
        color: $color-grey-dark;
    }
}