.select {
    width: 100%;
    position: relative;

    &::after {
        content: "";
        width: 1.4rem;
        height: .8rem;
        background-color: #000;
        clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }

    &__input {
        width: 100%;
        background-color: $color-grey-light-1;
        appearance: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $color-primary;
        padding: 1.2rem 1.3rem;
        font-family: $font-primary;
        font-size: 1.8rem;
        font-weight: 500;
        outline: none;
        &::-ms-expand {
            display: none;
        }

        &:disabled {
            background-color: #dedede;
        }
    }
}