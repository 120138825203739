.content-layout {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    row-gap: 8rem;

    font-size: 1.6rem;

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}