.services-input {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;

    &__item {
        display: flex;
        align-items: center;

        & > *:nth-child(2) {
            margin-right: 1rem;
        }
        & > *:nth-child(3) {
            width: 7rem;
        }
    }
}