.heading {
    font-family: $font-primary;
    display: flex;
    align-items: center;
    font-weight: 500;

    &--primary {
        font-size: 5rem;
    }

    &--secondary {
        font-size: 3rem;
    }
}
