.bookings {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    justify-items: center;
    row-gap: 4rem;

    & button {
        justify-self: end;
    }
}
