.scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.barber-sidebar {
    &__figure {
        width: 12rem;
        height: 12rem;
        padding: 0.5rem;

        margin-right: 4rem;

        border-radius: 5px;
        background-color: $color-grey-light-1;

        display: flex;
        justify-content: center;

        & img {
            height: 100%;
            width: auto;
        }
    }

    &__group {
        & h5 {
            font-size: 2.3rem;
            font-weight: 500;
            border-bottom: 1px solid $color-grey-light-2;
            padding-bottom: 3px;

            margin-bottom: 2rem;
            color: $color-grey-dark;
        }

        &:not(:last-child) {
            margin-bottom: 4rem;
        }
    }
}

.form-group {
    width: 80%;

    & label {
        display: block;
        font-size: 1.9rem;
        font-weight: 500;
        color: $color-grey-dark;
        margin-bottom: 0.5rem;
    }

    & input,
    & textarea {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $color-primary;
        padding: 1.2rem 1.3rem;
        font-family: $font-primary;
        font-size: 1.8rem;
        font-weight: 500;
        outline: none;
        background-color: $color-grey-light-1;
    }

    &:not(:last-of-type) {
        margin-bottom: 2rem;
    }

    &:last-of-type {
        margin-bottom: 4rem;
    }
}
