.btn {
    font-family: $font-primary;
    font-size: 1.5rem;
    font-weight: 500;

    padding: 1.4rem 4rem;

    border: none;
    outline: none;

    background-color: $color-primary;
    color: $color-black;

    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        filter: brightness(0.93);
    }

    &--rounded {
        border-radius: 8rem;
    }

    &--squared {
        border-radius: 5px;
    }

    &:disabled {
        background-color: $color-grey-light-1;
        cursor: not-allowed;

        &:hover {
            filter: none;
        }
    }
}
