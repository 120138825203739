.barbers {
    display: grid;
    grid-template-rows: repeat(2, min-content);
    row-gap: 7rem;

    &__cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30rem ,1fr));
        grid-gap: 2rem;
    }
}