.sidebar {
    width: 100%;
    height: 100%;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;


    &__background {
        width: 100%;
        height: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(#000, .45);
    }

    &__content {
        position: relative;

        height: 100%;
        background-color: #fff;
        width: 35%;

        padding: 5rem;
        padding-bottom: 13rem;
    }

    &__title {
        font-size: 3rem;
        font-weight: 500;
    }
    
    &__default {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
    }
}