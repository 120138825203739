.error {
    color: #C70000;

    display: flex;
    align-items: center;

    margin-top: .3rem;
    & svg {
        margin-right: .7rem;
    }

    &--small {
        font-size: 1.3rem;
    }

    &--medium {
        font-size: 1.7rem;
        margin-top: 1.3rem;

        & img {
            margin-right: .7rem;
            width: 1.8rem;
        }
    }

    &--large {
        font-size: 2.2rem;
        margin-top: 1.5rem;

        & img {
            margin-right: .7rem;
            width: 2.2rem;
        }
    }
}