.pagination {
    display: flex;
    align-items: center;

    &__icon {
        cursor: pointer;
        transition: all .15s ease-out;

        & a svg {
            width: .8rem;
        }

        &--inactive {
            opacity: .5;
        }

        &:first-of-type {transform: translateX(1rem);}
        &:last-of-type {transform: translateX(-1rem);}
    }

    &__page {
        font-size: 2rem;
        opacity: .5;
        transition: all .15s ease-out;
        
        &:hover {
            opacity: 1;
        }
        
        &--active {
            opacity: 1;
        }
    }

    &__link {
        text-decoration: none;
        color: #000;
    }

    & li {
        list-style: none;
        &:not(:last-child) {
            margin-right: 3rem;
        }
    }
}