.date-range {
        display: flex;
        align-items: center;

        & span {
            margin: 0 1.5rem;
        }

        & svg {
            height: 4rem;
            width: 4rem;
            cursor: pointer;

            &:hover {
                background-color: $color-white;
                border: 2px solid $color-primary;
                border-radius: 47%;
                box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
            }
        }
    }
