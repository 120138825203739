.dismiss {
    background: none;
    outline: none;
    border: none;

    display: grid;
    justify-content: center;
    align-content: center;
    
    z-index: 1;
    cursor: pointer;
    
    &__icon {
        position: relative;
        transform: rotate(45deg);

        &,
        &::after {
            border: none;
            border-radius: 0;
            background-color: #000;
            display: inline-block;
        }

        &::after {
            content: '';
            position: absolute;
            top: 1.2rem;
            left: 0;
            transform: translateY(-1.2rem) rotate(90deg);
        }
    }

    &__icon--large {
        &,
        &::after {
            border: none;
            border-radius: 0;
            height: 3px;
            width: 3rem;
            background-color: #000;
            display: inline-block;
        }
    }
    &__icon--small {
        &,
        &::after {
            border: none;
            border-radius: 0;
            height: 2px;
            width: 1.5rem;
            background-color: #000;
            display: inline-block;
        }
    }
}
