.table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.5rem;
}

.table thead tr {
    font-size: 1.5rem;
    color: $color-grey-dark;
    text-align: left;
    background-color: #f7f7f7;
}

.table tbody {
    border-bottom: 0.4rem solid $color-primary;
    border-radius: 30%;
}

.table tr {
    &:hover {
        background-color: #f7f7f7;
    }
}

.table th,
.table td {
    text-align: center;

    &:not(:first-child) {
        padding: 2.7rem 0rem;
    }
}

.table th {
    font-family: inherit;
    font-weight: 300;
}

.table td {
    font-weight: 400;
}
