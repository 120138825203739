.products {
    display: grid;
    row-gap: 7rem;
    justify-items: center;

    &__items {
        justify-self: start;
        width: 100%;
        & > * {
            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }
    }

    &__actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
