
//////////////////////////////////
// ANIMATION FOR SIDEBAR
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 70%;
    }

    100% {
        opacity: 1;
    }
}

.fadeInAnimation {
    animation-name: fadeIn;
    animation-duration: 1.3s;
    animation-timing-function: ease-out;
    animation-delay: .2;
}