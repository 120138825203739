.nav {
    background-color: $color-black;
    height: 100vh;

    padding: 8rem 0;
    
    flex: 0 0 16%;

    display: flex;
    flex-direction: column;

    &__logo-box {
        display: flex;
        justify-content: center;

        margin-bottom: 7rem;
        & svg {
            height: 7.5rem;
        }
    }

    &__content {
        list-style: none;
        margin-bottom: auto;
    }

    &__item {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 0.3rem;
        }
    }

    &__item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        background-color: rgba($color-primary, 0.98);
        transform: scaleY(0);
        transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
    }

    &__item:hover::before,
    &__item--active::before {
        transform: scaleY(1);
        width: 100%;
    }

    &__item:active::before {
        background-color: $color-primary;
    }

    &__link:link,
    &__link:visited {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.5rem 3rem;

        font-weight: 400;
        font-size: 2.1rem;
        color: white;
        text-decoration: none;

        z-index: 10;
        position: relative;

        transform: translateX(-1rem);
        & svg {
            width: 3rem;
            height: auto;
            margin-right: 2rem;
        }
    }

    &__logout {
        display: flex;
        align-items: center;
        justify-content: center;
        
        border: none;
        outline: none;
        
        font-size: 2rem;
        background-color: transparent;
        color: $color-white;
        text-align: center;
        padding: 2.5rem;
        margin-bottom: 3rem;
        margin-left: -1rem;
        text-decoration: none;
        
        transform: translateX(-1rem);
        cursor: pointer;
        
        & svg {
            width: 3rem;
            height: auto;
            margin-right: 2rem;
        }
    }
}

