.booking-sidebar {
    overflow-y: scroll;
    max-height: 100%;

    & h5 {
        font-size: 2.3rem;
        font-weight: 500;
        border-bottom: 1px solid $color-grey-light-2;
        padding-bottom: 3px;

        margin-bottom: 2rem;
        color: $color-grey-dark;
    }

    &__form {
        & > *:nth-child(3), & > *:nth-child(4) {
            width: 40%;
        }
    }
}