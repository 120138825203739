.table-accordion {
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & span {
            margin-right: 1rem;
        }

        & svg {
            width: 1rem;
            fill: $color-primary-dark;
        }

        &:hover {
            & svg {
                transform: rotate(180deg) translateY(1px);
            }
        }

        &--active {
            & svg {
                transform: rotate(180deg) translateY(1px);
            }
        }
    }

    &__list {
        list-style: none;
        margin-top: 1rem;
        font-size: 1.45rem;
        font-weight: 500;
        display: grid;

        justify-items: start;
        justify-content: center;

        & li {
            &:not(:last-child) {
                margin-bottom: .3rem;
            }
        }

        & li::before {
            content: "\2022";  
            color: $color-primary-dark; 
            font-weight: bold; 
            display: inline-block;
            width: 1.2em; 
            margin-left: -1em; 
        }
    }
}