.product {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    &__title {
        font-size: 2.5rem;
        font-weight: 500;

    }

    &__figure {
        width: 17rem;
        height: 17rem;
        padding: 1rem;

        margin-right: 4rem;

        border-radius: 5px;
        background-color: $color-grey-light-1;

        display: flex;
        justify-content: center;

        & img {
            height: 100%;
            width: auto;
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }
    
    &__main {
        width: 100%;
        padding: 4rem 6rem;
        background-color: #fff;
        box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
    }
}
