@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap" rel="stylesheet">');

// COLOR VARIABLES

// PRIMARY COLOR
$color-primary: #ffbe05;
$color-primary-dark: #ce9801;
$color-primary-light: #fde296;

// MAIN COLOR
$color-black: #1d1d1d;
$color-white: #fff;

//ADDITIONAL COLOR
$color-grey-light: #FCFCFC;
$color-grey-light-1: #f4f4f2;
$color-grey-light-2: #B3B4C6;
$color-grey-light-3: #F4F4FB;

$color-grey-dark: #727272;

// FONT VARIABLES
$font-primary: 'Space Grotesk', sans-serif;

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% => 1rem = 10px
}

body {
    font-family: $font-primary;
    font-size: 1.6rem;
    color: #000;
    line-height: 1.3;
}
