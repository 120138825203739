.main-layout {
    display: grid;
    grid-template-columns: 18% 1fr;
    height: 100vh;

    &__content {
        padding: 8rem;
        overflow-y: scroll;
    }
}
